

.full-page-4 {
    background: #fff;
   
}
.horizontal-order {
  -moz-column-count: 3;
  column-count: 3;padding-left: 0px;}

.bg-cinza {background-color: #f8f9fc;}
.bg-claro {background-color: #F8F1ED;}
.bg-escuro {background-color: #000;}
hr.home{background-color:#9966ff !important;width: 100%; }
.model-email {width: 80%;border-radius: 3%;}
.thumb-inner{position: relative; }
h1.title {font-family: 'Barlow'; font-size: 60px;}
.pd-top-120 {padding-top: 120px;}
.pd-bottom-120 {padding-bottom: 120px;}
.section-title {
    margin-bottom: 50px;
    position: relative;}
 .sub-title {
        border-radius: 30px;
        box-shadow: 0 4.4px 30px rgba(19, 16, 34, 0.1);
        background: #fff;
        padding: 8px 20px;
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 15px;
        letter-spacing: 1px;
      }
      .title {
        margin-bottom: 0;
        margin-top: 10px;
       
        font-size: 2.2rem;
        font-family: 'Barlow'
      }

      .content {
        margin-top: 20px;
        margin-bottom: 30px;
      }
      .small-title {
        margin-top: 18px;
        line-height: 36px;
      }

      .btn {
        border: 0;
        border-radius: 3px;
        font-weight: 600;
        font-size: 15px;
        overflow: hidden;
        padding: 12px 24px;
        position: relative;
        transition: all .5s ease;
        z-index: 0;}

        .btn-border-base {
          
            background-color: #EBFF70;
            color: #101a29;
           
        }
        
        .btn-border-base:hover {
          
          background-color: #000;
          color: #EBFF70;
         
      }
    .mt-10{margin-top: 10px;}
    .mt-20{margin-top: 20px;}
    .mt-40 {margin-top: 40px;}
    .mt-50 {margin-top: 50px;}
    .pt-20 {padding-top: 20px;}
    .pt-40 {padding-top: 40px;}
    .pt-80 {padding-top: 80px;}

    .mb-50 {margin-bottom: 50px;}
    .mb-40 {margin-bottom: 40px;}
    .mb-100 {margin-bottom: 100px;}
      .pb-12 {padding-bottom: 12px;}
    .py-md-40 {padding-bottom: 40px;}
    .py-md-80 {padding-bottom: 80px;}
    .p-b-5 {padding-bottom: 5px;}
    .pb-10 {padding-bottom: 10px;}
   .pb-150 {padding-bottom: 150px;}
   .pb-80 {padding-bottom: 80px;}
   .pb-40 {padding-bottom: 40px;}
    
    .mr-20 {margin-right: 20px;}
    .pr-50 {padding-right: 50px;}
    .pr-10 {padding-right: 10px;}
    .pr-20 {padding-right: 20px;}
    .mr-10 {padding-right: 10px;}

    .pl-50 {padding-left: 50px;}
    .w-40 {width: 40%;}  
  
   #cta .horizontal-order li:before {
      background: #9966ff;
      border-radius: 50%;
      color: #fff;
      content: attr(data-count);
      display: inline-block;
      left: 0;
      height: 0;
      font-size: 12px;
      font-weight: 600;
      height: 30px;
    
      line-height: 30px;
      position: absolute;
      text-align: center;
      
      width: 30px
  }
  is-1 {
    --indent-size: 4px;
  }
  ol {list-style: none;}
  .title-box {font-family: 'Barlow'; font-size:23px ; font-weight: 600; line-height: 35px;

  }
  #cta .horizontal-order li{position: relative;     break-inside: avoid;    margin-bottom: 20px;
    padding-left:44px;
  }
  img.logo-is, img.logo-be {vertical-align: bottom !important;}

  .nav-tabs .nav-link {
    border: 1px solid #9966ff;
    border-radius: 3px !important;
    background-color: transparent;
    padding-right: 20px;
    color: #9966ff;
  }

  .nav-tabs {border-bottom: none !important; justify-content: center;}
  .nav-tabs .nav-item {margin-right: 30px; margin-top: 30px;}

  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #EBFF70;
    background-color: #000;
    border: 1px solid #000 !important;
    box-shadow: 0 4.4px 30px rgba(19, 16, 34, 0.1);

  }

  .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    background-color: #000;
    color: #EBFF70;
    border: 1px solid #000;
  }

  button:focus {
    outline: none !important;
  }