/* recuperaod css*/

/* 
#template-investsmart .images, #template-investsmart .social-media {
	padding-bottom: 30px;
} */



/* No Mobile */

@media (max-width: 1000px) {

	.container-wrapper {
		display: block !important;
	}

	.is-capa {display: none;}
	#cta {display: none;}
	.is-area {display: none;}

	#page {
		display: none;
	}

	.faqs-btn-mobile {
		display: block !important;
	}
	
	.faqs-btn{
		display: none;
	}
}


.faqs-btn-mobile {
	position: fixed;
	bottom: 30px;
	right: 30px;
	background: white;
	padding: 25px 20px;
	border-radius: 75%;
	font-size: 14px;
	color: black;
	font-weight: bolder;
	cursor: pointer;
	box-shadow: 1px 1px 5px rgb(0 0 0 / 80%);
	display: none;
}

.container-wrapper {
	display: none;
}

.gzLGZz {
    height: 100vh;
    width: 100vw;
    padding-left: 0px;
    /*background: linear-gradient(-45deg, rgb(106, 120, 209), rgb(0, 164, 189));*/
	background-color: #000;
    transition: padding 0.5s ease 0s;
    position: relative;
}

.ibKKBG {
    width: 33.3%;
    margin-bottom: 2rem;
}

.ctrYKo {
    width: 100%;
    height: 100%;
   
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 25%;
}


.cjHcCP {
    position: relative;
    color: rgb(255, 255, 255);
    font-weight: 600;
    padding: 2rem 0px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.cjHcCP::before {
    content: "";
    width: 5rem;
    height: 1px;
    background-color: rgb(255, 255, 255);
    opacity: 0.2;
    transform: translateX(-1rem);
}

.cjHcCP::after {
    content: "";
    width: 5rem;
    height: 1px;
    background-color: rgb(255, 255, 255);
    opacity: 0.2;
    transform: translateX(1rem);
}

.gqCMAi {
    padding: 1rem 2rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: rgb(45, 62, 80);
    background-color: rgb(255, 255, 255);
}


.fGoFae {
    width: 80%;
    margin: 0px auto;
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 2.125rem;
}

.kqiwji {
    position: absolute;
    left: 0px;
    bottom: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
}

.chfHFV {
    margin: 0px auto;
    width: 10rem;
}






/* Tratamento de erros input */

.error-sub {
	margin-top: 3px;
	font-size: 14px;
	color: red;
	font-weight: bold;

}

.error-input {
    border-bottom: 1px solid red !important;
}

.span-erros {
	color: red;
}

/* Classe para subir telecone comercial */

.alinhamento-span {
	display: inline !important;
}

.sumir-comercial {
	visibility: hidden;
}

.logo-bnkrio {
	width: 134px;
	height: 20px;
}

.div-infos {
	position: relative;
}

.span-infos {
    font-size: 0.9rem;
    font-weight: 600;
    color: rgb(255, 255, 255);
}

.input-infos {
    background: none;
}

::placeholder {
    color: #ccc;
}
:root,
body {
	--company-color: #000;
	--company-2nd-color: #ebff70;
	--company-3nd-color: #9966ff;
	--besmasrt-claro:#F8F1ED;
	--besmart-cor-2: #9966ff;
	--besmart-cor-3: #111111;
	--besmart-cor-4:#ebff70;



}

/* general */

a {
	text-decoration: none;
}
hr {
	background: var(--company-2nd-color);
	width: 100%;
}
hr.vertical-is {
	width: 1px;
	height: 8rem;
	border-left: 5px solid  #000 !important;
	margin-left: 30px;
}
hr.vertical {
	width: 1px;
	height: 8rem;
	border-left: 5px solid  #e4ff70 !important;
	margin-left: 30px;
}
hr.faqs-divisor {
	background: #cbd6e2;
	margin: 5rem 0;
}

/* colors */
#template-bankrio {
	--color1: #000;
	--color2: #9966ff;
	--color3: #e4ff70;
}
#template-investsmart {
	--color1: #9966ff;
	--color2: #9966ff;
}
#template-bnkrio {
	--color1: #9966ff;
	--color2: #9966ff;
}


.mt-15{
	margin-top: 15px !important;
}


.mt-30{
	margin-top: 30px !important;
}

button.btn-best {
	background-color: var(--company-color);
	border: none;
	border-radius: 15px;
	
}
button.btn-best:hover{
	background-color: var(--company-2nd-color);
	color: var(--company-color);
	
}

.bg-roxo {
	background-color: #9966FF !important;
}

.bg-black {
	background-color: #000 !important;
}

.bg-white {
	background-color: #f8f1ed;
}

.bg-verde {
	background-color: #EBFF70;
}


/* texts */

.text-black {
	color: #000;
}
.text-verde {
	color: #EBFF70;
}
.text-roxo {
color: #9966FF;
}



.title-bar h2 {
	font-size: 18px;
	color: var(--besmart-cor-3);
	font-weight: 800;
}
.subtitle-bar h3 {
	font-size: 16px;
	color: white;
	font-weight: 600;
}
h3.subtitle-header-3 {
	font-size: 18px;
	font-weight: 400;
}
h4.item-title {
	font-size: 15px;
	padding-bottom: 10px;
	font-weight: 550;
	color: white;
}
span.copyright {
	color: white;
	text-align: center;
	font-size: 12px;
}
.profile-name h5 {
	font-size: 18px;
	letter-spacing: -.5px
}
.profile-info {
	display: grid;
}
.profile-info span {
	font-size: 14px;
	font-weight: 400;
    letter-spacing: -.5px;
}
span.text-separator {
	margin: 0 5px;
}
.contact-block span {
	font-size: 12px;
	font-weight: 400;
    letter-spacing: -.5px;
}
span.create-ur-signature {
	font-size: 13px;
	font-weight: 400;
    letter-spacing: -.5px;
    text-decoration: underline;
}
.footer-newsletter-opt .create-newsletter .create-btn,
.confirm-signature .confirm-btn {
	color: #000;
	font-weight: 500;
	border-radius: 5px;
	
}
.footer-newsletter-opt .clear-all .clean-text {
	color: var(--besmart-cor-2);
	text-transform: uppercase;
	font-weight: 500;
	font-size: 12px;
	padding-left: 15px;
}
.bg-success {background-color: #EBFF70 !important; color: #000 !important; border-radius: 10px;} 

.copyright-text {
	font-size: 12px;
	color: var(--besmart-cor-3);
}

h3.content-body-header { color: var(--besmart-cor-3);

}
/* menu bar - divs*/
.full-page {
	padding-left: 25%;
	height: 100vh;
    width: 100vw;
  /*  background: linear-gradient(-45deg, rgb(106, 120, 209), rgb(0, 164, 189));*/
  background: var(--besmasrt-claro);
    transition: padding 0.5s ease 0s;
    position: relative;
}
.full-page-2,
.full-page-3 {
	height: 100%;
    width: 100%;
 /*  background: linear-gradient(-45deg, rgb(106, 120, 209), rgb(0, 164, 189));*/
 	background: var(--besmasrt-claro);
    transition: padding 0.5s ease 0s; 
    position: relative;
}
.top-menu {
	display: none;
	background: rgb(51, 71, 91);
	padding: 20px 15px;
}
.menu-navbar-btn {
	color: white;
}
.lateral-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 25%;
    max-width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.lateral-bar .info-form-content > div > span {
	padding: 15px 0;
}
input:focus {
	outline: 0;
}
.lateral-bar input:focus-visible{
	border-bottom: 1px solid #9966FF;
	color:#9966FF ;
}
.lateral-bar input {
	width: 100%;
    background: transparent;
    border: 0;
    border-bottom: 1px solid #111111;
	padding: 15px 0 5px;
    color: #111111;
}

.top-bar-header {
	position: relative;
	top: 0;
	width: 100%;
	margin: 0;
	min-height: 40px;
	cursor: pointer;
	height: 90px;
	border-top: 10px solid #9966FF;
}

.top-bar-bg-verde {
	border-top: 10px solid #EBFF70 !important;
}

.top-bar-header .painel-top-btn.active {
	background:var(--besmasrt-claro);
	border-left: 5px #9966FF solid;
	border-right: 5px #9966FF solid ;
}
.content-bar {
	overflow-y: scroll;
	position: relative;
	padding: 40px 50px;
	height: 100%;
	background:var(--besmasrt-claro);
	
}
.content-be {
	background-color:#9966ff;
}
.title-bar,
.subtitle-bar {
	margin-bottom: 50px;
}
.subtitle-bar {
	display: flex;
	align-items: center;
	position: relative;
	justify-content: space-between;
}
.painel-top-btn {
	background: #464646;
	display: flex;
    align-items: center;
    place-content: center;
    border-right: 1px solid rgb(51, 71, 91);
}
.select-createhubs {
	position: relative;
    min-width: 50px;
    right: 0;
    width: 50px;
    height: 25px;
    border-radius: 3px;
    background-color: rgb(66, 91, 118);
    transition: background-color 0.2s linear 0s;
}
.btn-selector {
	position: absolute;
    width: 25px;
    height: 25px;
    left: 0;
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    transition: background-color 0.2s linear 0s;
}
.btn-selector.active {
	right: 0;
    background-color: rgb(255, 255, 255);
    transition: background-color 0.2s linear 0s;
}
.select-modelos .active img {
	opacity: 0.5;
}
.painel-top-btn i {
	color: var(--besmart-cor-2);
	font-size: 18px;
}
/*.createhubs-bar {
	
}*/
.select-modelos > div {
	margin: 50px 0;
}
.item-modelo {
	position: relative;
	cursor: pointer;
}
.item-modelo img {
	width: 100%;
}
.check-btncheck-btn {
	position: absolute;
	top: 35%;
	left: 45%;
}
.check-btn i {
	color: #EBFF70 !important ;
	font-size: 35px;
}
.footer-bar {
	margin-top: 75px;
}

/* window area */
.content-layout {
    width: 100%;
    height: 100%;
    float: right;
    overflow-y: scroll;
}
.header-layout {
    width: 100%;

	border-top: 10px solid #9966FF;
}
.voltar-editor {
	color: #9966FF;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 500;
	display: flex;
	align-items: center;
	cursor: pointer;
}
.voltar-editor i {
	margin-right: 10px;
	font-size: 14px;
}
.header-content {
	display: flex;
	width: 60%;
	justify-content: space-between;
}
.header-content .logo,
.top-menu .logo-navbar {
	width: 10rem;
}
.header-content .logo {
	display: flex;
	align-items: center;
}
.header-content .logo img:not(:last-child) {
	margin-right: 50px;
}
.header-content .selector-lang {
	color: white;
	padding: 5px 30px;
	border: 1px solid white;
	border-radius: 50px;
	font-size: 14px;
	cursor: pointer;
	display: flex;
	align-items: center;
}
.header-content .selector-lang i {
	padding-right: 5px;
}
.body-layout {
	display: flex;
	place-content: center;
	margin-top: 3%;
}
.body-layout-2 {
	display: flex;
    place-content: center;
    align-items: center;
}
.content-body-2 {
	width: 80%;
}
.step-row {
	display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 0px;
}
.step-item {
	display: flex;
    flex-direction: column;
    align-items: center;
}
.step-item span {
	padding: 8px 20px;
	background-color: rgb(51, 71, 91);
	color: white;
	border-radius: 50%;
	font-size: 25px;
	font-weight: bolder;
}
.step-item span#step-3 {
	background: transparent;
	border: 1px solid white;
}
.step-item:first-child {
	margin-right: 50px;
}
.step-item:nth-child(2) {
	margin: 0 50px;
}
.step-item:last-child {
	margin-left: 50px;
}
.step-item p {
	color: white;
	text-transform: uppercase;
	margin-top: 10px;
	font-size: 12px;
}
hr.step-separator {
	background: white;
	width: inherit;
	height: 2px;
	opacity: .5;
}
hr.step-separator.active {
	opacity: 1;
}
.form-body-2 {
	width: 100%;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.signature-body-3 {
	min-width: 900px;
    width: 100%;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
}
.window-content-3 {
	display: flex;
    align-items: center;
    flex-direction: column;
}
.header-body-2 {
	font-size: 2rem;
    line-height: 1.3125;
    font-weight: 700;
    width: 780px;
    color: #111111; 
    text-align: center;
    margin: 50px 4px;
}
.content-form {
	display: flex;
    flex-direction: column;
    max-width: 610px;
    margin-bottom: 2.5rem;
}
.form-step-2 {
	display: flex;
    flex-direction: column;
    max-width: 610px;
    margin-bottom: 2.5rem;
}
.form-container {
	display: flex;
    flex-wrap: wrap;
    min-width: 610px;
}
.form-row {
	width: calc(50% - 22px);
    margin: 30px 0px;
}
.form-row:nth-child(2n+1) {
	margin-right: 22px;
}
.form-row:nth-child(2n) {
    margin-left: 22px;
}
.form-item {
	position: relative;
    display: flex;
    flex-direction: column;
}
.form-label {
	font-size: 0.9rem;
    font-weight: 600;
    color: rgb(255, 255, 255);
}
.form-item input {
	background: none rgb(255, 255, 255);
    border-top: none;
    border-right: none;
    border-left: none;
    border-image: initial;
    outline: none;
    font-size: 1rem;
    padding: 0.6rem;
    color: rgb(51, 71, 91);
    border-bottom: 1px solid rgb(255, 255, 255);
    border-radius: 3px;
	padding: 0.5rem 0px;
    color: rgb(255, 255, 255);
}
.form-selector-header {
	color: rgb(255, 255, 255);
    font-weight: 600;
    padding-bottom: 1rem;
}
.form-selector-qtd {
	color: rgb(255, 255, 255);
}
.form-selector-info {
	font-size: 0.9rem;
    font-weight: 600;
    color: rgb(255, 255, 255);
}
.form-selector {
	position: relative;
    width: 100%;
    padding: 4rem 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.form-selector-line {
	width: 100%;
    height: 0.2rem;
    background-color: rgb(255, 255, 255);
    opacity: 0.2;
    cursor: pointer;
}
.form-selector-fulfilled {
	position: absolute;
    top: 50%;
    left: 0px;
    width: 0px;
    height: 0.2rem;
    background-color: rgb(255, 255, 255);
    transform: translateY(-50%);
    pointer-events: none;
}
.form-selector-pointer {
	position: absolute;
    left: 0px;
    top: 50%;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    cursor: pointer;
    transform: translateY(-50%);
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 3px 0px;
}
.form-selector-info-qtd {
	position: absolute;
    top: 0px;
    left: 50%;
    width: 10em;
    font-size: 15px;
    font-weight: 500;
    color: rgb(255, 255, 255);
    text-align: center;
    transform: translate(-50%, -125%);
    will-change: transform;
}
.terms-and-conditions p {
	font-size: 0.75rem;
    max-width: 580px;
    text-align: center;
    padding: 1rem 0px;
    margin-top: auto;
    font-weight: 500;
    color: rgb(255, 255, 255);
}
.footer-layout-2 {
	display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    flex-shrink: 0;
    margin-top: 120px;
}
.footer-container-2 {
	display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    pointer-events: all;
}
.footer-logo-layout-2 .logo img {
	width: 7.75rem;
}
.window-screen {
	width: 70%;
	border-radius: 50px;
}
.signature-body-3 .window-screen,
.signature-body-3 .header-body-2,
.signature-body-3 .btns-opt {
	width: 80%;
}
.window-screen .top-window {
	display: flex;
	background: rgb(51, 71, 91);
	align-items: center;
	padding: 14px 15px;
	border-bottom: 1px solid rgb(72, 90, 108);
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
}
.window-screen .top-window span {
	opacity: .9;
}
.top-infomessage-window {
	background: rgb(51, 71, 91);
	padding: 20px 40px;
}
.btn-close,
.btn-min,
.btn-max {
	height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    margin-right: 0.5rem;
}
.btn-close {
	background: red;
}
.btn-min {
	background: orange;
}
.btn-max {
	background: green;
}
.top-infomessage-window .to-message,
.top-infomessage-window .subject-message {
	display: flex;
	color: white;
	font-size: 14px;
}
.content-window {
	display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
	padding: 90px 10px;
	background: white;
	border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.header-content .logo img {
	height: 30px ;
}
.body-window {
	display: flex;
    align-items: center;
    place-content: center;
    width: 750px;
	max-width: 750px;
    flex-direction: column;
}

.footer-window{
	height: 60px;
	

}

.profile-img,
.company-profile-logo {
	display: flex;
	place-content: center;
}
.profile-img img {
	width: 8rem;
}
.profile-img img {
	width: 100%;
	min-width: 80%;
	/*border: 2px solid #000;*/
	border-radius: 50%;
}
.company-profile-logo {
	margin: 25px 0;
}
.company-profile-logo img {
	width: 100%;
}

.company-slogan h3 {
	color: var(--company-color);
	text-align: center;
	font-size: 13px;
}
.social-media-is {
	display: flex;
    place-content: center;
}

.social-media {
	display: flex;
    place-content: center;
}

.place-content-start {
	place-content: start !important;
}


.sm-bg {
	background: #262626 !important;
  
}

.social-media span {
	background: #9966FF;
    padding: 1px 8px;
    border-radius: 50%;
    margin: 0 2px;
}


.social-media span#face {
	padding:  1px 9px;
}

.social-media span#youtube {
	padding:  1px 7px;
}
.social-media i {
	font-size: 13px;
	color: white;
}
.info-member-window {
	display: flex;
	width: 100%;
	align-items: center;
}
.info-member-window > div:first-child {
	display: flex;
	align-items: center;
}
.contact-block span {
	display: block;
	margin-top: 8px;
}
.contact-block i {
	color: var(--company-2nd-color);
	margin-right: 15px;
}
span.create-ur-signature {
	cursor: pointer;
}
.footer-newsletter-opt {
	text-align: center;
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.create-newsletter,
.confirm-signature {
	display: flex;
    place-content: center;
	margin-bottom: 20px;
}
.create-newsletter .create-btn,
.confirm-signature .confirm-btn {
	padding: 15px 25px;
	background: rgb(51, 71, 91);
	width: fit-content;
	cursor: pointer;
}
.footer-newsletter-opt .clear-all {
	display: flex;
	align-items: center;
	place-content: center;
	cursor: pointer;
	margin-top: 20px;
}
.footer-newsletter-opt .clear-all i {
	color: var(--besmart-cor-2);
}

.faqs-layout {
	z-index: 9999;
	background: white;
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	place-content: center;
	display: none;
}
.faqs-content {
	max-width: 680px;
    width: 100%;
    margin-top: 150px;
	height: auto;
    padding: 10px 10px;
}
.faqs-layout img {
	width: 100%;
}
.faqs-layout span.close-btn,
.faqs-content-header h2,
.faqs-content-body li.stepitems,
.faqs-content-body li.stepitems::marker {
	color: #33475b;
}
.faqs-layout span.close-btn {
	position: fixed;
    right: 40px;
    top: 20px;
    font-size: 40px;
    cursor: pointer;
}
.faqs-content-header h2 {
	text-align: center;
	font-weight: 900;
}
.faqs-content-body {
	margin: 75px 0;
}
.faqs-content-body ul {
	list-style: decimal;
}
li.stepitems::marker {
	font-weight: 700;
}
.faqs-content-body h3 {
	font-size: 24px;
	color: #33475b;
	margin: 25px 0;
}
.faqs-content-body li.stepitems {
	margin: 50px 0;
	font-weight: lighter;
}
.faqs-content-body li.stepitems:first-child {
	margin: 25px 0 50px;
}
.faqs-content-body img {
	margin: 25px 0;
}

/* footer - faqs balloon */
.faqs-btn {
	position: fixed;
	bottom: 30px;
	right: 30px;
	background: white;
    padding: 25px 20px;
    border-radius: 75%;
    font-size: 14px;
    color: black;
    font-weight: bolder;
    cursor: pointer;
    box-shadow: 1px 1px 5px rgb(0 0 0 / 80%);
}
.btns-opt {
	margin: 25px 0;
	display: flex;
}
.btns-opt span {
	padding: 1rem 2rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    min-height: 90px;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.btns-opt .copy-signature {
    color: rgb(255, 255, 255);
    background-color: rgb(51, 71, 91);
}
.btns-opt .copy-fontcode {
    color: #F8F1ED;
    background-color: var(--besmart-cor-2);
}
.btns-opt .send-friends {
    color: rgb(255, 255, 255);
    background-color: rgb(51, 71, 91);
}
.btns-opt-social a {
	display: flex;
    font-size: 0.875rem;
    font-weight: 600;
    color: rgb(255, 255, 255);
    text-decoration: none;
    line-height: 1.125rem;
    cursor: pointer;
    margin: 0.5rem 0px;
}
.btns-opt-social a img {
	height: 1rem;
    width: 1rem;
    margin-left: 0.5rem;
}
.btns-opt-social span {
	background-color: rgb(255, 255, 255);
    width: 2px;
    height: 1.875rem;
    opacity: 0.3;
}
.extra-content-header {
	position: relative;
    margin-top: 8rem;
    margin-bottom: 6rem;
    display: flex;
    place-content: center;
}
.extra-content-header span {
	color: white;
    background: rgb(51, 71, 91);
    font-size: 12px;
    text-transform: uppercase;
    padding: 2px 8px;
    border-radius: 50px;
    z-index: 9999;
}
.extra-content-header:before {
	content: '';
	background: rgb(255, 255, 255);
    opacity: 0.3;
    top: 50%;
    left: 0px;
    width: 100%;
    height: 2px;
    position: absolute;
}
.extra-content-body {
	margin-bottom: 13rem;
    text-align: center;
    width: 100%;
    color: rgb(255, 255, 255);
}
h2.content-body-header {
	font-size: 2rem;
    line-height: 1.3125;
    font-weight: 700;
}
.extra-content {
	display: flex;
    margin-top: 3.75rem;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    justify-content: center;
}
.extra-content-item {
	color: rgb(51, 71, 91);
    width: calc(33.33% - 1.875rem);
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
}
.extra-content-item:nth-child(2n+1) {
    margin-right: 20px;
}
.extra-content-item img {
	width: 100%;
    max-height: 17rem;
}
.ec-item-description {
	display: flex;
    flex-direction: column;
    padding: 1.25rem 1.875rem 0px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}
.ec-description-text {
	display: flex;
    flex-direction: column;
    width: 100%;
}
.ec-description-text h5 {
	font-size: 20px;
	color: rgb(51, 71, 91);
}
.ec-description-text p {
	font-size: 13px;
}
.ec-item-description a {
	text-decoration: none;
    display: inline-block;
    font-size: 0.875rem;
    padding: 0.6rem 1.875rem;
    margin-top: 1.5rem;
    margin-bottom: 1.875rem;
    background: rgb(51, 71, 91);
    color: white;
}

/* RESPONSIVES */

/* min 1600px */
@media only screen and (min-width: 1600px) {
	.full-page {
		padding-left: 400px !important;
	}
}

/* max 991px */
@media (max-width: 991px) {
	.info-company-window {
		flex-direction: column;
	}
	.faqs-layout span.close-btn {
		right: 10px;
		font-size: 16px;
	}
}
/* max 767px */
@media only screen and (max-width: 767px) {
	.full-page {
		padding-left: 0;
	}
	.top-menu {
		display: flex;
		position: fixed;
		width: 100%;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		z-index: 99999;
	}
	.lateral-bar {
		height: auto;
		width: 100%;
		position: unset;
		max-width: unset;
		display: none;
	}
	.content-bar {
		display: none;
	}
	.top-bar-header {
		display: none;
		top: 4rem;
		flex-direction: column;
		place-content: flex-end;
	}
	.painel-top-btn {
		
		border-bottom: 1px solid ;
		padding: 10px;
	}
	.pre-header {
		padding: 50px 50px 0 50px;
	}
	.header-content .selector-lang {
		padding: 5px 10px;
		font-size: 12px;
	}
	.header-layout {
		display: none;
	}
	.body-layout {
		margin-top: 10rem;
	}
	.content-body-2 {
		margin-top: 10rem;
	}
	.header-body-2,
	.content-form,
	.form-container {
		max-width: 75%;
	}
	.form-step-2 {
    	align-items: center;
	}
	.form-container {
		flex-direction: column;
		min-width: unset;
	}
	.form-row {
		margin-left: 0 !important;
		width: 100%;
	}
	.form-row:nth-child(1) {
		margin-bottom: 0px;
	}
	.form-row:nth-child(2n+1) {
		margin-right: 0px;
	}
	.ec-description-text h5 {
		font-size: 14px;
	}
	.ec-description-text p {
		font-size: 12px;
	}
	.ec-item-description {
		padding: 2px;
	}
	.ec-item-description a {
		font-size: 12px;
		min-height: 50px;
		align-items: center;
		display: flex;
		min-height: 60px;
	}
	.info-member-window > div:first-child {
		margin-bottom: 15px;
	}
	hr.vertical {
		display: none;
	}
	.info-member-window {
		flex-direction: column;
	}
	.info-company-window {
		text-align: center;
		margin-bottom: 30px;
	}
	.company-profile-logo img {
		width: 100%;
	}
	.faqs-content-body {
		padding: 0 50px;
	}
}

/* max 499px */
@media (max-width: 499px) {
	.profile-name h5 {
		font-size: 14px;
	}
	.profile-info {
		font-size: 12px;
	}
	.profile-info span {
		font-size: 12px;
	}
	.profile-img img,
	.company-profile-logo img {
		width: 8rem;
	}
	/*.social-media > .row {
		display: grid;
	}*/
	.social-media span {
		margin: 2px 2px;
	}
	.top-infomessage-window {
		padding: 20px 15px;
	}
	.top-infomessage-window .to-message,
	.top-infomessage-window .subject-message {
		font-size: 11px;
	}
	.step-item {
		margin: 0 !important;
	}
	.company-slogan h3 {
		font-size: 15px;
	}

	
	.body-window {
		width: 100%;
	}
}

/* max 399px */
@media (max-width: 300px) {
	.info-member-window {
		flex-direction: column;
		align-items: center;
		width: 100%;
	}
	.info-member-window > div:first-child {
		flex-direction: column;
	}
	.info-member-window > div:first-child div {
		max-width: 100%;
	}
	.profile-img img {
		margin-bottom: 20px;
	}
	.faqs-content-body {
		padding: 0 15px;
	}
}

.mt-10{margin-top: 10px;}
.mt-20{margin-top: 20px;}
.mt-40 {margin-top: 40px;}
.mt-50 {margin-top: 50px;}
.pt-20 {padding-top: 20px;}
.pt-40 {padding-top: 40px;}
.pt-80 {padding-top: 80px;}

.mb-50 {margin-bottom: 50px;}
.mb-40 {margin-bottom: 40px;}
.mb-100 {margin-bottom: 100px;}
  .pb-12 {padding-bottom: 12px;}
.py-md-40 {padding-bottom: 40px;}
.py-md-80 {padding-bottom: 80px;}
.p-b-5 {padding-bottom: 5px;}
.pb-10 {padding-bottom: 10px;}
.pb-150 {padding-bottom: 150px;}
.pb-80 {padding-bottom: 80px;}
.pb-40 {padding-bottom: 40px;}

.mr-20 {margin-right: 20px;}
.pr-50 {padding-right: 50px;}
.pr-10 {padding-right: 10px;}
.pr-20 {padding-right: 20px;}
.mr-10 {padding-right: 10px;}

.pl-50 {padding-left: 50px;}
