@font-face {
    font-family: 'AvenirNext-CooperHewitt';
	src: url('/src/fonts/Cooper_Hewitt/CooperHewitt-Medium.ttf');
	
}

@font-face {
    font-family: 'AvenirNext';
	src: url('/src/fonts/AvenirNextRegularMetropolis/Metropolis-Regular.otf');
    font-weight: bolder;
}

@font-face {
    font-family: 'Knockout';
	src: url('/src/fonts/Knockout/Knockout-Regular.ttf');
}

@font-face {
    font-family: 'KnockoutBold';
	src: url('/src/fonts/Knockout/Knockout-Bold.otf');
    font-weight: bolder;
}

@font-face {
    font-family: 'Barlow';
	src: url('/src/fonts/Barlow/Barlow-Medium.ttf');
}

@font-face {
    font-family: 'Rounkey';
	src: url('/src/fonts/Rounkey/Roundkey-Medium.ttf');
}


@font-face {
    font-family: 'RounkeyBolder';
	src: url('/src/fonts/Rounkey/Roundkey-Bold.otf');
	font-weight: bolder;
}

/* general */
a {
	text-decoration: none;
}

a:hover {
	text-decoration: none;
} 

/* general */
h1, h2  {
	font-family: 'Barlow';
}

h3, h4, h5, h6, span, p, a {
	font-family: 'Barlow';
}

hr {
	background: var(--company-2nd-color);
	width: 100%;
}
hr.vertical {
	width: 1px;
	height: 8rem;
	border-left: 1px solid var(--company-2nd-color);
	margin-left: 30px;
}

.contents {
	display: contents;
}

:root,
body {
	--company-color: #172846;
	--company-2nd-color: #996600;
}

.company-profile-logo {
	margin: 25px 0;
	width: 200px;
}
.company-slogan-g20 {
	margin-right: 250px;
}

.social-media {
	display: flex;
    place-content: center;
}
.social-media span {
	background: var(--company-2nd-color);
    padding: 0px 7px;
    border-radius: 50%;
    margin: 0 2px;
}
.social-media i {
	font-size: 13px;
	color: white;
}

.info-company-window {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	min-height: 90px;
	padding: 0 15px;
}

.info-company-window-is {
	display: flex;
	align-items: center;
	width: 100%;
	min-height: 90px;
	padding: 0 15px;
}

/* general */
.borderimg {
	border-radius: 100%;
}

.faqs-layout li.stepitems span {
	font-size: 15px;
	letter-spacing: 1px;
	font-family: 'AvenirNext';
	font-weight: lighter;
}
a {
	text-decoration: none;
}
hr {
	background: var(--company-2nd-color);
	width: 100%;
}
hr.vertical {
	width: 1px;
	height: 8rem;
	border-left: 1px solid var(--company-2nd-color);
	margin-left: 30px;
}
hr.faqs-divisor {
	background: #cbd6e2;
	margin: 5rem 0;
}

#faqs-page {
	display: none;
}

.faqs-balloon {
	cursor: pointer;
}

.faqs-layout {
	z-index: 9999;
	background: white;
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	place-content: center;
	display: none;
}
.faqs-content {
	max-width: 680px;
    width: 100%;
    margin-top: 150px;
}
.faqs-layout img {
	width: 100%;
}
.faqs-layout span.close-btn,
.faqs-content-header h2,
.faqs-content-body li.stepitems,
.faqs-content-body li.stepitems::marker {
	color: #33475b;
}
.faqs-layout span.close-btn {
	position: fixed;
    right: 40px;
    top: 20px;
    font-size: 40px;
    cursor: pointer;
}
.faqs-content-header h2 {
	text-align: center;
	font-weight: 900;
}
.faqs-content-body {
	margin: 75px 0;
}
.faqs-content-body ul {
	list-style: decimal;
}
li.stepitems::marker {
	font-weight: 700;
}
.faqs-content-body h3 {
	font-size: 24px;
	color: #33475b;
	margin: 25px 0;
}
.faqs-content-body li.stepitems {
	margin: 50px 0;
	font-weight: lighter;
}
.faqs-content-body li.stepitems:first-child {
	margin: 25px 0 50px;
}
.faqs-content-body img {
	margin: 25px 0;
}

/* footer - faqs balloon */
.faqs-btn {
	position: fixed;
	bottom: 30px;
	right: 30px;
	background: white;
    padding: 25px 20px;
    border-radius: 75%;
    font-size: 14px;
    color: black;
    font-weight: bolder;
    cursor: pointer;
    box-shadow: 1px 1px 5px rgb(0 0 0 / 80%);
}

/* Alert */
.alert {
    --bs-alert-bg: #0000;
    --bs-alert-padding-x: 0.5rem;
    --bs-alert-padding-y: 1rem;
    --bs-alert-margin-bottom: 1rem;
    --bs-alert-color: inherit;
    --bs-alert-border-color: #0000;
    --bs-alert-link-color: inherit;
    border-radius: 1px;
    margin-bottom: 1rem;
    padding: 1rem;
    position: relative;
}

.alert-warning {
    color: #ffda6a;
    background-color: #332701;
    border-color: #ffda6a;
}

p.text-small {font-size: 16px; 	font-weight: 100; margin-bottom: 0px;
}

button.btn-close {
	
		--bs-btn-close-color: #000;
		--bs-btn-close-bg: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E);
		--bs-btn-close-hover-opacity: 0.75;
		--bs-btn-close-focus-shadow: 0 0 0 0.25rem #0d6efd40;
		--bs-btn-close-focus-opacity: 1;
		--bs-btn-close-disabled-opacity: 0.25;
		--bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
		background: #0000 var(--bs-btn-close-bg) center/1em auto no-repeat;
		border: 0;
		border-radius: 0.375rem;
		box-sizing: initial !important;
		height: 0.1em;
		opacity: 0.5;
		padding: 0.25em;
		width: 0.1em;
		filter: var(--bs-btn-close-white-filter);
		font-size: 15px;
}
.alert-dismissible .btn-close {
    padding: 1.25rem 1rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}
/* colors */
#template-bankrio {
	--color1: #172846;
	--color2: #996600;
}
#template-investsmart {
	--color1: #666699;
	--color2: #666699;
}
#template-bnkrio {
	--color1: #172844;
	--color2: #6699cc;
}

/* texts */
.title-bar h2 {
	font-size: 18px;
	color: white;
	font-weight: 800;
}
.subtitle-bar h3 {
	font-size: 16px;
	color: white;
	font-weight: 600;
}
h3.subtitle-header-3 {
	font-size: 18px;
	font-weight: 400;
}
h4.item-title {
	font-size: 16px;
	padding-bottom: 10px;
	font-weight: 700;
	color: white;
}
.createhubs-bar .footer-bar .copyright {
	color: white;
	text-align: center;
	font-size: 12px;
}
.profile-name h5 {
	font-size: 18px;
	letter-spacing: -.5px
}
.profile-info {
	display: grid;
}
.profile-info span {
	font-size: 14px;
	font-weight: 400;
    letter-spacing: -.5px;
}
span.create-ur-signature {
	font-size: 14px;
	font-weight: 400;
    letter-spacing: -.5px;
    text-decoration: underline;
}
.footer-newsletter-opt .create-newsletter .create-btn,
.confirm-signature .confirm-btn {
	color: white;
	font-weight: 500;
}
.footer-newsletter-opt .clear-all .clean-text {
	color: white;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 12px;
	padding-left: 15px;
}
.copyright-text {
	font-size: 12px;
	color: white;
}

/* menu bar - divs*/
.full-page {
	padding-left: 25%;
	height: 100vh;
    width: 100vw;
    background: linear-gradient(-45deg, rgb(106, 120, 209), rgb(0, 164, 189));
    transition: padding 0.5s ease 0s;
    position: relative;
}
.full-page-home {

	height: 100vh;
    
    background: linear-gradient(-45deg, rgb(106, 120, 209));
}
.full-page-2 {
	height: 100%;
    width: 100%;
    background: linear-gradient(-45deg, rgb(106, 120, 209), rgb(0, 164, 189));
    transition: padding 0.5s ease 0s;
    position: relative;
}
.top-menu {
	display: none;
	background: rgb(51, 71, 91);
	padding: 20px 15px;
}
.menu-navbar-btn {
	color: white;
}
.lateral-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 25%;
    max-width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.top-bar-header {
	position: relative;
	top: 0;
	width: 100%;
	margin: 0;
	min-height: 40px;
	cursor: pointer;
}
.top-bar-header .painel-top-btn.active {
	background: rgb(51, 71, 91);
	height: 90px;
}
.content-bar {
	overflow-y: scroll;
	position: relative;
	padding: 40px 50px;
	height: 100%;
	background: rgb(51, 71, 91);
}
.title-bar,
.subtitle-bar {
	margin-bottom: 50px;
}
.subtitle-bar {
	display: flex;
	align-items: center;
	position: relative;
	justify-content: space-between;
}
.painel-top-btn {
	background: rgb(66, 91, 118);
	display: flex;
    align-items: center;
    place-content: center;
    border-right: 1px solid rgb(51, 71, 91);
}
.select-createhubs {
	position: relative;
    min-width: 50px;
    right: 0;
    width: 50px;
    height: 25px;
    border-radius: 3px;
    background-color: rgb(66, 91, 118);
    transition: background-color 0.2s linear 0s;
}
.btn-selector {
	position: absolute;
    width: 25px;
    height: 25px;
    left: 0;
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    transition: background-color 0.2s linear 0s;
}
.btn-selector.active {
	right: 0;
    background-color: rgb(255, 255, 255);
    transition: background-color 0.2s linear 0s;
}

.select-modelos .active img {
	opacity: 0.5;
}
.painel-top-btn i {
	color: white;
	font-size: 18px;
}
/*.createhubs-bar {
	
}*/
.select-modelos > div {
	margin: 50px 0;
}
.item-modelo {
	position: relative;
	cursor: pointer;
}

.item-modelo img {
	width: 100%;
}
.check-btn {
	position: absolute;
	top: 25%;
	left: 45%;
}
.check-btn i {
	color: white;
	font-size: 30px;
}
.createhubs-bar .footer-bar {
	margin-top: 75px;
}

/* window area */
.content-layout {
    width: 100%;
    height: 100%;
    float: right;
    overflow-y: scroll;
}
.header-layout {
    align-items: center;
}
.voltar-editor {
	color: white; 
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 500;
	display: flex;
	align-items: center;
	cursor: pointer;
    margin-left: 30px;
}
.voltar-editor i {
	margin-right: 10px;
	font-size: 14px;
}
.header-content {
	display: block;
}
.header-content .logo,
.top-menu .logo-navbar {
	width: 10rem;
}
.header-content .selector-lang {
	color: white;
	padding: 5px 30px;
	border: 1px solid white;
	border-radius: 50px;
	font-size: 14px;
	cursor: pointer;
}
.header-content .selector-lang i {
	padding-right: 5px;
}
.body-layout {
	display: flex;
	place-content: center;
	margin-top: 2%;
}
.body-layout-2 {
	display: flex;
    place-content: center;
    align-items: center;
}
.content-body-2 {
	width: 80%;
}
.step-row {
	display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 0px;
}
.step-item {
	display: flex;
    flex-direction: column;
    align-items: center;
}
.step-item span {
	padding: 8px 20px;
	background-color: rgb(51, 71, 91);
	color: white;
	border-radius: 50%;
	font-size: 25px;
	font-weight: bolder;
}
.step-item span#step-3 {
	background: transparent;
	border: 1px solid white;
}
#step-1 {
	margin-right: 50px;
}
#step-2 {
	margin: 0 50px;
}
#step-3 {
	margin-left: 50px;
}
.step-item p {
	color: white;
	text-transform: uppercase;
	margin-top: 10px;
	font-size: 12px;
}
hr.step-separator {
	background: white;
	width: inherit;
	height: 2px;
	opacity: .5;
}
hr.step-separator.active {
	opacity: 1;
}
.form-body-2 {
	width: 100%;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.signature-body-3 {
	min-width: 900px;
    width: 100%;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
}
.window-content-3 {
	display: flex;
    align-items: center;
    flex-direction: column;
}
.header-body-2 {
	font-size: 2rem;
    line-height: 1.3125;
    font-weight: 700;
    width: 780px;
    color: rgb(255, 255, 255);
    text-align: center;
    margin: 4px;
}
.content-form {
	display: flex;
    flex-direction: column;
    max-width: 610px;
    margin-bottom: 2.5rem;
}
.form-step-2 {
	display: flex;
    flex-direction: column;
    max-width: 610px;
    margin-bottom: 2.5rem;
}
.form-container {
	display: flex;
    flex-wrap: wrap;
    min-width: 610px;
}
.form-row {
	width: calc(50% - 22px);
    margin: 30px 0px;
}
.form-row:nth-child(2n+1) {
	margin-right: 22px;
}
.form-row:nth-child(2n) {
    margin-left: 22px;
}
.form-item {
	position: relative;
    display: flex;
    flex-direction: column;
}
.form-label {
	font-size: 0.9rem;
    font-weight: 600;
    color: rgb(255, 255, 255);
}
.form-item input {
	background: none rgb(255, 255, 255);
    border-top: none;
    border-right: none;
    border-left: none;
    border-image: initial;
    outline: none;
    font-size: 1rem;
    padding: 0.6rem;
    color: rgb(51, 71, 91);
    border-bottom: 1px solid rgb(255, 255, 255);
    border-radius: 3px;
}
.form-selector-header {
	color: rgb(255, 255, 255);
    font-weight: 600;
    padding-bottom: 1rem;
}
.form-selector-qtd {
	color: rgb(255, 255, 255);
}
.form-selector-info {
	font-size: 0.9rem;
    font-weight: 600;
    color: rgb(255, 255, 255);
}
.form-selector {
	position: relative;
    width: 100%;
    padding: 4rem 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.form-selector-line {
	width: 100%;
    height: 0.2rem;
    background-color: rgb(255, 255, 255);
    opacity: 0.2;
    cursor: pointer;
}
.form-selector-fulfilled {
	position: absolute;
    top: 50%;
    left: 0px;
    width: 0px;
    height: 0.2rem;
    background-color: rgb(255, 255, 255);
    transform: translateY(-50%);
    pointer-events: none;
}
.form-selector-pointer {
	position: absolute;
    left: 0px;
    top: 50%;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    cursor: pointer;
    transform: translateY(-50%);
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 3px 0px;
}
.form-selector-info-qtd {
	position: absolute;
    top: 0px;
    left: 50%;
    width: 10em;
    font-size: 15px;
    font-weight: 500;
    color: rgb(255, 255, 255);
    text-align: center;
    transform: translate(-50%, -125%);
    will-change: transform;
}
.terms-and-conditions p {
	font-size: 0.75rem;
    max-width: 580px;
    text-align: center;
    padding: 1rem 0px;
    margin-top: auto;
    font-weight: 500;
    color: rgb(255, 255, 255);
}
.footer-layout-2 {
	display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    flex-shrink: 0;
    margin-top: 120px;
}
.footer-container-2 {
	display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    pointer-events: all;
}
.footer-logo-layout-2 .logo img {
	width: 7.75rem;
}
.window-screen .top-window {
	display: flex;
	background: rgb(51, 71, 91);
	align-items: center;
	padding: 14px 15px;
	border-bottom: 1px solid rgb(72, 90, 108);
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
}
.window-screen .top-window span {
	opacity: .9;
}
.top-infomessage-window {
	background: rgb(51, 71, 91);
	padding: 20px 40px;
}
.btn-close,
.btn-min,
.btn-max {
	height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    margin-right: 0.5rem;
}
.btn-close {
	background: red;
}
.btn-min {
	background: orange;
}
.btn-max {
	background: green;
}
.top-infomessage-window .to-message,
.top-infomessage-window .subject-message {
	display: flex;
	color: white;
	font-size: 14px;
}
.content-window {
	display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
	padding: 90px 10px;
	background: white;
	border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.profile-img,
.company-profile-logo {
	display: flex;
	place-content: center;
}
.company-profile-logo {
	margin: 25px 0;
}
.social-media {
	display: flex;
    place-content: center;
}
.social-media span {
	background: rgb(106, 120, 209);
    padding: 0px 8px;
    border-radius: 50%;
    margin: 0 2px;
}
.social-media i {
	font-size: 13px;
	color: white;
}
.info-member-window {
	display: flex;
	width: 100%;
}
.profile-info {
	display: grid;
}
.contact-block i {
	color: rgb(242, 84, 125);
	margin-right: 15px;
}
span.create-ur-signature {
	cursor: pointer;
}
.footer-newsletter-opt {
	text-align: center;
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.create-newsletter,
.confirm-signature {
	display: flex;
    place-content: center;
	margin-bottom: 20px;
}
.create-newsletter .create-btn,
.confirm-signature .confirm-btn {
	padding: 15px 25px;
	background: rgb(51, 71, 91);
	width: fit-content;
	cursor: pointer;
}
.footer-newsletter-opt .clear-all {
	display: flex;
	align-items: center;
	place-content: center;
	cursor: pointer;
}
.footer-newsletter-opt .clear-all i {
	color: white;
}

/* footer - faqs balloon */
.faqs-btn {
	position: fixed;
	bottom: 30px;
	right: 15px;
	background: white;
    padding: 25px 20px;
    border-radius: 75%;
    font-size: 14px;
    color: black;
    font-weight: bolder;
    box-shadow: 1px 1px 5px rgb(0 0 0 / 80%);
}
.btns-opt {
	margin: 25px 0;
	display: flex;
}
.btns-opt span {
	padding: 1rem 2rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    min-height: 90px;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.btns-opt .copy-signature {
    color: rgb(255, 255, 255);
    background-color: rgb(51, 71, 91);
}
.btns-opt .copy-fontcode {
    color: rgb(45, 62, 80);
    background-color: rgb(255, 255, 255);
}
.btns-opt .send-friends {
    color: rgb(255, 255, 255);
    background-color: rgb(51, 71, 91);
}
.btns-opt-social {
	display: flex;
    -webkit-box-pack: justify;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 2rem;
}
.btns-opt-social a {
	display: flex;
    font-size: 0.875rem;
    font-weight: 600;
    color: rgb(255, 255, 255);
    text-decoration: none;
    line-height: 1.125rem;
    cursor: pointer;
    margin: 0.5rem 0px;
}
.btns-opt-social a img {
	height: 1rem;
    width: 1rem;
    margin-left: 0.5rem;
}
.btns-opt-social span {
	background-color: rgb(255, 255, 255);
    width: 2px;
    height: 1.875rem;
    opacity: 0.3;
}
.extra-content-header {
	position: relative;
    margin-top: 8rem;
    margin-bottom: 6rem;
    display: flex;
    place-content: center;
}
.extra-content-header span {
	color: white;
    background: rgb(51, 71, 91);
    font-size: 12px;
    text-transform: uppercase;
    padding: 2px 8px;
    border-radius: 50px;
    z-index: 9999;
}
.extra-content-header:before {
	content: '';
	background: rgb(255, 255, 255);
    opacity: 0.3;
    top: 50%;
    left: 0px;
    width: 100%;
    height: 2px;
    position: absolute;
}
.extra-content-body {
	margin-bottom: 13rem;
    text-align: center;
    width: 100%;
    color: rgb(255, 255, 255);
}
h2.content-body-header {
	font-size: 2rem;
    line-height: 1.3125;
    font-weight: 700;
}
.extra-content {
	display: flex;
    margin-top: 3.75rem;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    justify-content: center;
}
.extra-content-item {
	color: rgb(51, 71, 91);
    width: calc(33.33% - 1.875rem);
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
}
.extra-content-item:nth-child(2n+1) {
    margin-right: 20px;
}
.extra-content-item img {
	width: 100%;
    max-height: 17rem;
}
.ec-item-description {
	display: flex;
    flex-direction: column;
    padding: 1.25rem 1.875rem 0px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}
.ec-description-text {
	display: flex;
    flex-direction: column;
    width: 100%;
}
.ec-description-text h5 {
	font-size: 20px;
	color: rgb(51, 71, 91);
}
.ec-description-text p {
	font-size: 13px;
}
.ec-item-description a {
	text-decoration: none;
    display: inline-block;
    font-size: 0.875rem;
    padding: 0.6rem 1.875rem;
    margin-top: 1.5rem;
    margin-bottom: 1.875rem;
    background: rgb(51, 71, 91);
    color: white;
}

/*max 1280 */
@media only screen and (max-width: 1400px) {
	.company-slogan-g20 {
		margin-right: 170px;
	}
	
}

/* min 1600px */
@media only screen and (min-width: 1600px) {
	.full-page {
		padding-left: 400px !important;
	}
}

/* max 767px */
@media only screen and (max-width: 767px) {
	.full-page {
		padding-left: 0;
	}

	.top-menu {
		display: flex;
		position: fixed;
		width: 100%;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		z-index: 99999;
	}
	.lateral-bar {
		height: auto;
		width: 100%;
		position: unset;
		max-width: unset;
		display: none;
	}
	.content-bar {
		display: none;
	}
	.top-bar-header {
		display: none;
		top: 4rem;
		flex-direction: column;
		place-content: flex-end;
	}
	.painel-top-btn {
		border-right: 0;
		border-bottom: 1px solid rgb(51, 71, 91);
		padding: 10px;
	}
	.header-content .selector-lang {
		padding: 5px 10px;
		font-size: 12px;
	}
	.header-layout {
		display: none;
	}
	.body-layout {
		margin-top: 10rem;
	}
	.content-body-2 {
		margin-top: 10rem;
	}
	.header-body-2,
	.content-form,
	.form-container {
		max-width: 75%;
	}
	.form-step-2 {
    	align-items: center;
	}
	.form-container {
		flex-direction: column;
		min-width: unset;
	}
	.form-row {
		margin-left: 0 !important;
		width: 100%;
	}
	.form-row:nth-child(1) {
		margin-bottom: 0px;
	}
	.form-row:nth-child(2n+1) {
		margin-right: 0px;
	}
	.ec-description-text h5 {
		font-size: 14px;
	}
	.ec-description-text p {
		font-size: 12px;
	}
	.ec-item-description {
		padding: 2px;
	}
	.ec-item-description a {
		font-size: 12px;
		min-height: 50px;
		align-items: center;
		display: flex;
		min-height: 60px;
	}
}



/* max 499px */
@media (max-width: 499px) {
	.profile-name h5 {
		font-size: 14px;
	}
	.profile-info {
		font-size: 12px;
	}
	.profile-info span {
		font-size: 12px;
	}
	.profile-img img,
	.company-profile-logo img {
		width: 5rem;
	}
	.social-media > .row {
		display: grid;
	}
	.social-media span {
		margin: 2px 0;
	}
	.top-infomessage-window {
		padding: 20px 15px;
	}
	.top-infomessage-window .to-message,
	.top-infomessage-window .subject-message {
		font-size: 11px;
	}
	.step-item {
		margin: 0 !important;
	}
}

/* max 399px */
@media (max-width: 300px) {
	.info-member-window {
		flex-direction: column;
		align-items: center;
		width: 100%;
	}
}

label {
    padding: 20px 10px;
    width: 200px;
    background-color: #333;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    display: block;
    margin-top: 10px;
    cursor: pointer;
}

input[type="file"] {
	display: none;
}

input:disabled {
  opacity: 0.5;
}

.cropper-crop-box, .cropper-view-box {
  border-radius: 100%;
}